<template>
    <div class="no_network_container">
        <Header></Header>
        <div class="container">
            <div class="no_network_bg">
                <div class="reason_box">
                    <h2 class="no_network_title">暂无网络</h2>
                    <ul class="no_network_reason">
                        <li>请试试一下方法：</li>
                        <li>· 检查网络连接</li>
                        <li>· 刷新页面重新进入</li>
                    </ul>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
export default {
    name: 'noNetwork'
}
</script>
<style scoped>
    .no_network_container {
        /* width: 100%; */
        /* background-color: #eee; */
        padding-top: 110px;
    }
    .no_network_container .container .no_network_bg {
        margin-bottom: 50px;
        box-shadow: 0 0 10px #ccc;
        /* width: 100%; */
        height: 847px;
        background: url('~@/assets/defaultGraph/no_network.png') no-repeat center;
        background-size: cover;
        /* text-align: center; */
        position: relative;
    }
    .no_network_container .container .no_network_bg .reason_box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .no_network_title {
        font-size: 64px;
        color: #302C48;
    }
    .no_network_reason {
        font-size: 21px;
        color: #302C48;
        margin-top: 32px;
        text-align: center;
    }
    .no_network_reason li {
        margin-bottom: 20px;
    }
</style>